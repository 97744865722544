import React, { useState, useMemo } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { graphql } from 'gatsby';
import * as moment from 'moment';

import { BannerWithBackground as Banner } from '../components/common';
import {
  labelTranslations,
  detailsButtonTranslations,
  privateTagTranslations,
  filterTranslations,
} from '../translations/ratings';
import { selectOption } from '../translations/forms';
import { Layout } from '../layout/Layout';
import DataTable from '../components/modules/calificaciones/DataTable';

export const query = graphql`
  query CalificacioesQuery {
    site {
      siteMetadata {
        siteUrl
        cmsUrl
      }
    }
    directus {
      ratings_page {
        banner_image {
          id
        }
        banner_image_mobile {
          id
        }
      }
      ratings_page_translations {
        title
        description
        languages_code {
          code
        }
      }
      calificaciones(sort: "-date", limit: -1) {
        id
        imf
        type
        country
        date
        rate
        reporte {
          id
          filename_download
        }
      }
    }
  }
`;

const Calificaciones = ({ data, pageContext }) => {
  const cmsURL = data.site.siteMetadata.cmsUrl;
  const labelTranslation = labelTranslations[pageContext.languageCode];
  const tableHeaders = [
    labelTranslation.imf,
    labelTranslation.type,
    labelTranslation.country,
    labelTranslation.date,
    labelTranslation.rate,
    labelTranslation.reporte,
  ];

  const { ratings_page, ratings_page_translations, calificaciones } = data.directus;
  const { banner_image_mobile, banner_image } = ratings_page;
  const { title, description } = ratings_page_translations.filter(
    t => t.languages_code.code === pageContext.languageCode
  )[0];

  const [filterSelected, setFilterSelected] = useState({
    imf: '',
    type: '',
    country: '',
    rate: '',
    date: '',
  });

  const filters = useMemo(() => {
    let typeItems = [];
    let countryItems = [];
    let ratingItems = [];
    let dateItems = [];

    if (calificaciones.length) {
      for (const { type, country, rate, date } of calificaciones) {
        if (!ratingItems.includes(rate)) ratingItems.push(rate);
        if (!countryItems.includes(country)) countryItems.push(country);
        if (!typeItems.includes(type)) typeItems.push(type);

        const formatedDate = moment(date).format('YYYY-MM');

        if (!dateItems.includes(formatedDate)) {
          dateItems.push(formatedDate);
        }
      }
    }

    typeItems = typeItems.sort();
    countryItems = countryItems.sort();
    ratingItems.sort();
    dateItems = dateItems.sort((a, b) => {
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
    });

    return { countryItems, ratingItems, typeItems, dateItems };
  }, [calificaciones]);

  const filteredRatings = calificaciones.filter(calificacion => {
    return (
      (calificacion.imf.toLowerCase().includes(filterSelected.imf.toLowerCase()) || filterSelected.imf === '') &&
      (calificacion.type === filterSelected.type || filterSelected.type === '') &&
      (calificacion.country === filterSelected.country || filterSelected.country === '') &&
      (calificacion.rate === filterSelected.rate || filterSelected.rate === '') &&
      (calificacion.date.includes(filterSelected.date) || filterSelected.date === '')
    );
  });

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Layout pageId="calificaciones" currentLanguage={pageContext.languageCode}>
      <Banner
        description={description}
        title={title}
        desktopImage={`${cmsURL}/assets/${banner_image.id}`}
        mobileImage={`${cmsURL}/assets/${banner_image_mobile.id}`}
      />

      <div className="container">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <form className="datatable-filter row">
              <section className="col-md-4 mx-auto">
                <TextField
                  fullWidth
                  label={filterTranslations[pageContext.languageCode]['imf']}
                  id="buscar"
                  onChange={({ target }) => {
                    setFilterSelected({ ...filterSelected, imf: target.value });
                    setCurrentPage(1);
                  }}
                />
              </section>
              <section className="col-md-2 mx-auto">
                <TextField
                  fullWidth
                  select
                  label={filterTranslations[pageContext.languageCode]['type']}
                  onChange={({ target }) => {
                    setFilterSelected({ ...filterSelected, type: target.value });
                    setCurrentPage(1);
                  }}
                >
                  <MenuItem value="">{selectOption[pageContext.languageCode]}</MenuItem>
                  {filters.typeItems.map(type => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </section>
              <section className="col-md-2 mx-auto">
                <TextField
                  fullWidth
                  select
                  label={filterTranslations[pageContext.languageCode]['country']}
                  onChange={({ target }) => {
                    setFilterSelected({ ...filterSelected, country: target.value });
                    setCurrentPage(1);
                  }}
                >
                  <MenuItem value="">{selectOption[pageContext.languageCode]}</MenuItem>
                  {filters.countryItems.map(country => (
                    <MenuItem value={country}>{country}</MenuItem>
                  ))}
                </TextField>
              </section>
              <section className="col-md-2 mx-auto">
                <TextField
                  fullWidth
                  select
                  label={filterTranslations[pageContext.languageCode]['date']}
                  onChange={({ target }) => {
                    setFilterSelected({ ...filterSelected, date: target.value });
                    setCurrentPage(1);
                  }}
                >
                  <MenuItem value="">{selectOption[pageContext.languageCode]}</MenuItem>
                  {filters.dateItems.map(date => (
                    <MenuItem value={date}>{date}</MenuItem>
                  ))}
                </TextField>
              </section>
              <section className="col-md-2 mx-auto">
                <TextField
                  fullWidth
                  select
                  label={filterTranslations[pageContext.languageCode]['rate']}
                  onChange={({ target }) => {
                    setFilterSelected({ ...filterSelected, rate: target.value });
                    setCurrentPage(1);
                  }}
                >
                  <MenuItem value="">{selectOption[pageContext.languageCode]}</MenuItem>
                  {filters.ratingItems.map(rate => (
                    <MenuItem value={rate}>{rate}</MenuItem>
                  ))}
                </TextField>
              </section>
            </form>

            {filteredRatings.length > 0 ? (
              <DataTable
                headers={tableHeaders}
                rows={filteredRatings}
                privateTag={privateTagTranslations[pageContext.languageCode]}
                detailsButton={detailsButtonTranslations[pageContext.languageCode]}
                cmsURL={cmsURL}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              <NoResultsMessage />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const NoResultsMessage = () => {
  return (
    <div className="no-result-message">
      <p>No results</p>
    </div>
  );
};

export default Calificaciones;
