import React, { useRef } from 'react';
import { usePagination } from '@mantine/hooks';
import * as moment from 'moment';
import { Stars } from '../../common/Stars.jsx';
import PrevArrow from '../../common/PrevArrow';
import NextArrow from '../../common/NextArrow';

const ITEMS_PER_PAGE = 10;

const DataTable = ({ headers, rows, privateTag, detailsButton, cmsURL, currentPage, setCurrentPage }) => {
  const pagination = usePagination({
    total: Math.ceil(rows.length / ITEMS_PER_PAGE),
    page: currentPage,
    onChange: setCurrentPage,
  });

  const start = (currentPage - 1) * ITEMS_PER_PAGE;
  const end = start + ITEMS_PER_PAGE;

  const visibleRows = rows.slice(start, end);

  const headerRef = useRef();

  return (
    <>
      <div className="datatable-wrapper" ref={headerRef}>
        <table className="datatable">
          <thead>
            <tr>
              {headers.map(label => (
                <th key={label}>{label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {visibleRows.map(row => (
              <TableRow key={row.id} row={row} privateTag={privateTag} detailsButton={detailsButton} cmsURL={cmsURL} />
            ))}
          </tbody>
        </table>
      </div>

      {rows.length > 10 && <Pagination pagination={pagination} scrollRef={headerRef} />}
    </>
  );
};

const Pagination = ({ pagination, scrollRef }) => {
  const scroll = () => {
    if (scrollRef) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handlePrev = () => {
    scroll();
    pagination.previous();
  };

  const handleNext = () => {
    scroll();
    pagination.next();
  };

  const handlePage = page => {
    scroll();
    pagination.setPage(page);
  };

  return (
    <div className="col-md-11 mx-auto pagination-buttons">
      <PrevArrow className="btn arrow-btn" onClick={handlePrev} disabled={pagination.active === 1} />
      {pagination.range.map((pageNumber, index) => {
        if (pageNumber === 'dots') {
          return (
            <div key={index} className="btn">
              &#8230;
            </div>
          );
        }

        return (
          <button
            key={index}
            className={`btn number-btn ${pagination.active === pageNumber ? 'active' : undefined}`}
            onClick={() => handlePage(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
      <NextArrow
        className="btn arrow-btn"
        onClick={handleNext}
        disabled={pagination.active === pagination.range[pagination.range.length - 1]}
      />
    </div>
  );
};

const TableRow = ({ row, privateTag, detailsButton, cmsURL }) => {
  return (
    <tr>
      <td className="bold">{row.imf}</td>
      <td>{row.type}</td>
      <td>{row.country}</td>
      <td>{moment(row.date).format('YYYY-MM')}</td>
      <RatingTableData type={row.type} rate={row.rate} privateTag={privateTag} />
      <ReportTableData
        reportURL={row.reporte ? `${cmsURL}/assets/${row.reporte.id}/${row.reporte.filename_download}` : null}
      >
        {detailsButton}
      </ReportTableData>
    </tr>
  );
};

const RatingTableData = ({ type, rate, privateTag }) => {
  return (
    <td>
      {rate === 'Privado' ? (
        <section className="tag">{privateTag}</section>
      ) : type === 'Social' ? (
        <Stars number={Number(rate)} />
      ) : (
        <section className="tag">{rate}</section>
      )}
    </td>
  );
};

const ReportTableData = ({ reportURL, children }) => {
  return (
    <td>
      {reportURL ? (
        <a className="btn primary-btn" href={reportURL}>
          <span>{children}</span>
        </a>
      ) : (
        <button className="btn primary-btn" disabled>
          <span>{children}</span>
        </button>
      )}
    </td>
  );
};

export default DataTable;
